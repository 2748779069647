import {
  HStack,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link as LinkR } from 'react-router-dom';
import { Link } from 'react-scroll';

const Navbar = ({ toggle }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const menuItems = [
    {
      path: 'acasa',
      name: 'Acasă',
    },
    {
      path: 'despre',
      name: 'Despre VOX',
    },
    {
      path: 'beneficii',
      name: 'Beneficii',
    },
    {
      path: 'descopera',
      name: 'Descoperă',
    },
    {
      path: 'cta',
      name: 'Parteneriat',
    },
  ];

  const [navColor, setNavColor] = useState(false);

  const scrollEvent = (e) => {
    window.scrollY >= 80 ? setNavColor(true) : setNavColor(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);

    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);
  
  return (
    <HStack
      id={'nav'}
      w={'full'}
      h={'80px'}
      justifyContent={'space-between'}
      px={{ base: '1rem', lg: '3rem' }}
      bg={navColor ? 'brand.301' : 'transparent'}
      color={'titleDark'}
      position={{ base: 'initial', lg: 'fixed' }}
      top={0}
      left={0}
      zIndex={1000}
    >
      <LinkR to="/">
        <Image w={'80px'} src={'/Logo.png'} alt={'VOX Press'} />
      </LinkR>
      <HStack gap={'2rem'}>
        {isDesktop ? (
          menuItems.map((item) => (
            <Text
              key={item.name}
              fontWeight={'bold'}
              letterSpacing={'2px'}
              textTransform={'uppercase'}
              cursor={'pointer'}
              _hover={{
                borderBottom: '3px solid',
                borderBottomColor: 'contrast',
              }}
            >
              {' '}
              <Link
                to={item.path}
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                {item.name}
              </Link>
            </Text>
          ))
        ) : (
          <IconButton
            variant="ghost"
            color={'textLight'}
            icon={<FiMenu fontSize="1.5rem" />}
            aria-label="Open Menu"
            _hover={'none'}
            _active={'none'}
            _focus={'none'}
            onClick={toggle}
          />
        )}
      </HStack>
    </HStack>
  );
};

export default Navbar;
