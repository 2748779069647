import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-scroll';

const menuItems = [
  {
    path: 'acasa',
    name: 'Acasă',
  },
  {
    path: 'despre',
    name: 'Despre VOX',
  },
  {
    path: 'beneficii',
    name: 'Beneficii',
  },
  {
    path: 'descopera',
    name: 'Descoperă',
  },
  {
    path: 'cta',
    name: 'Parteneriat',
  },
];
const MobileNav = ({ isOpen, toggle }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Stack
      className="mobileNav"
      display={isDesktop ? 'none' : 'flex'}
      w={'350px'}
      top={0}
      transition={'all .8s ease-in-out'}
      right={isOpen ? 0 : '-360px'}
      position={'fixed'}
      zIndex={10001}
      bg={'brand.301'}
      color={'text'}
      h={'100vh'}
      px={'2rem'}
      py={'2rem'}
      gap={'1.4rem'}
    >
      <HStack w={'full'} justifyContent={'flex-end'}>
        {' '}
        <CloseIcon color={'text'} fontSize={'1.5rem'} onClick={toggle} />
      </HStack>
      {menuItems.map((item) => (
        <Link
          onClick={toggle}
          key={item.name}
          to={item.path}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-80}
        >
          <Text
            textAlign={'right'}
            fontSize={'1.25rem'}
            _hover={{
              borderBottom: '3px solid',
              borderBottomColor: 'contrast',
            }}
            textTransform={'capitalize'}
          >
            {item.name}
          </Text>
        </Link>
      ))}
    </Stack>
  );
};

export default MobileNav;
