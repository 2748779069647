import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { HelmetProvider } from 'react-helmet-async';
import { myTheme } from './theme/myTheme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={myTheme}>
        <App />
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>
);
