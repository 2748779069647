import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, Toc, Privacy } from './pages';
import ScrollToTop from './ScrollToTop';
import ScrollTopButton from './components/scrollTopButton';
import { Stack } from '@chakra-ui/react';
import { Navbar, Footer, MobileNav } from './components';
import { useState } from 'react';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Router>
      <ScrollToTop />
      <Navbar toggle={toggle} />
      <MobileNav toggle={toggle} isOpen={isOpen} />
      <Stack gap={0} position={'relative'}>
        <ScrollTopButton />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/termeni-conditii" element={<Toc />} />
          <Route path="/gdpr" element={<Privacy />} />
        </Routes>
        <Footer />
      </Stack>
    </Router>
  );
}

export default App;
