import { Stack } from '@chakra-ui/react';
import React from 'react';
import { AreaComponent, Cta, Hero, Services } from '../components';
import {
  despreInfo,
  discoverInfo,
  joinInfo,
  serviciiInfo,
} from '../components/areaCompTexts';
import ScrollBar from '../components/ScrollBar';

const Home = () => {
  return (
    <Stack w={'full'} minH={'100vh'} id="acasa" overflow={'hidden'} gap={0}>
      <Hero />
      <Cta />
      <ScrollBar />
      <AreaComponent data={despreInfo} />

      <Services data={serviciiInfo} />

      <Cta />
      <AreaComponent data={discoverInfo} />
      <AreaComponent data={joinInfo} />
    </Stack>
  );
};

export default Home;
