import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { gdprText } from '../components/legalData';

const Privacy = () => {
  return (
    <Stack
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
      pt={'110px'}
    >
      <Helmet>
        <title>Politică de confidențialitate - VOX Press</title>
      </Helmet>
      <Flex
        flexDir={'column'}
        w={'full'}
        maxW={'100rem'}
        flexDirection={'column'}
      >
        <Heading as={'h1'}>Politică de confidențialitate</Heading>
        <Text>Ultima Actualizare Martie 09, 2024</Text>
        <Text pt={'2rem'}>
          This privacy notice for Digidev Innotech SRL (doing business as VOX
          Press) (<strong>'Company', 'we', 'us', or 'our'</strong>), describes
          how and why we might collect, store, use, and/or share (
          <strong>'process'</strong>) your information when you use our services
          (<strong>'Services'</strong>), such as when you:
        </Text>
        <UnorderedList>
          <ListItem>
            Visit our website at{' '}
            <Link
              target="_blank"
              aria-label="VOX Press"
              rel="noreferrer"
              href="https://voxpress/ro"
            >
              https://voxpress/ro
            </Link>{' '}
            or any website of ours that links to this privacy notice
          </ListItem>
          <ListItem>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </ListItem>
        </UnorderedList>

        <Text>
          <strong> Questions or concerns?</strong> Reading this privacy notice
          will help you understand your privacy rights and choices. If you do
          not agree with our policies and practices, please do not use our
          Services. If you still have any questions or concerns, please contact
          us at contact@autopost.ro.
        </Text>
        <Accordion my="2rem" allowMultiple>
          {gdprText.map((item) => (
            <AccordionItem key={item.title}>
              <AccordionButton>
                <Box flex={1} textAlign="left">
                  <Heading as="h2" fontSize="2xl" textTransform="uppercase">
                    {item.title}
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                {item.continut.map((item, index) => (
                  <>
                    {item.subtitlu !== '' && (
                      <Heading as={'h3'}>{item.subtitlu}</Heading>
                    )}
                    {item.text.map((item, index) => (
                      <Box key={index} mb="1rem" textAlign="justify">
                        {item}
                      </Box>
                    ))}
                  </>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Stack>
  );
};

export default Privacy;
