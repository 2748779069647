import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Services = ({ data }) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <Stack
      id={'beneficii'}
      alignItems={'center'}
      justifyContent={'center'}
      bg={'brand.100'}
      w={'full'}
      minH={'50rem'}
      gap={'2rem'}
      py={'2rem'}
      px={'1rem'}
    >
      <Heading
        color={'brand.301'}
        textAlign={'center'}
        py={'2rem'}
        fontSize={'2.5rem'}
      >
        Vrei să știi ce beneficii ai?
      </Heading>
      <HStack
        alignItems={'center'}
        justifyContent={'center'}
        gap={'2rem'}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        {data.map((item) => (
          <Card
            key={item.nume}
            w={'350px'}
            h={'510px'}
            bg={'brand.300'}
            color={'text'}
            data-aos={item.AosEffect}
            data-aos-duration={item.AosDuration}
            data-aos-easing={item.AosEasing}
            data-aos-delay={item.AosDelay}
          >
            <CardHeader>
              <Image
                w={'300px'}
                h={'200px'}
                objectFit={'contain'}
                src={item.src}
                alt={item.nume}
              />
            </CardHeader>
            <CardBody>
              <Heading as={'h3'} fontSize={'1.55rem'} textAlign={'center'}>
                {item.nume}
              </Heading>
              <Text pt={5}>{item.descriere}</Text>
            </CardBody>
          </Card>
        ))}
      </HStack>
    </Stack>
  );
};

export default Services;
