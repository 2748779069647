import { HStack, Heading, Image, Stack } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import MessageBox from './MessageBox';

const ScrollBar = () => {
  const [error, setError] = useState('');
  const [listaZiare, setListaZiare] = useState([]);
  const containerRef = useRef(null);
  useEffect(() => {
    const fetchZiare = async () => {
      try {
        const { data } = await axios.get(
          'https://api.voxpress.ro/api/ziar/prezentare'
        );
        setListaZiare([...data]);
      } catch (error) {
        setError(error);
      }
    };
    fetchZiare();
  }, []);

  useEffect(() => {
    const autoScroll = () => {
      if (containerRef.current) {
        if (
          containerRef.current.scrollLeft + containerRef.current.clientWidth >=
          containerRef.current.scrollWidth
        ) {
          containerRef.current.scrollLeft = 0; // Reset scroll to the start
        } else {
          containerRef.current.scrollLeft += 3; //scroll speed
        }
      }
    };

    const intervalId = setInterval(autoScroll, 50);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <Stack alignItems={'center'} py={'2rem'} gap={'2rem'}>
      {error !== '' ? (
        <MessageBox status={'error'}>{error}</MessageBox>
      ) : (
        <>
          <Heading as={'h2'} fontSize={'2.5rem'} textAlign={'center'}>
            Publicațiile partenere
          </Heading>
          <HStack
            className="scroller"
            ref={containerRef}
            overflowX="auto"
            w="100%"
            whiteSpace="nowrap"
          >
            <HStack>
              {listaZiare.map((item) => (
                <Image
                  key={item.nume}
                  w={'150px'}
                  h={'75px'}
                  objectFit={'contain'}
                  src={item.poza}
                  alt={item.nume}
                />
              ))}
            </HStack>
          </HStack>
        </>
      )}
    </Stack>
  );
};

export default ScrollBar;
