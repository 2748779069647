import {
  Button,
  Container,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const Cta = () => {
  return (
    <Container
      w={'full'}
      maxW={'100vw'}
      py={{ base: '1rem', md: '2rem' }}
      px={{ base: '1rem', lg: '3rem' }}
      bg={'brand.301'}
      color={'brand.100'}
    >
      <Stack
        spacing={{ base: '4', md: '5' }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Stack spacing={{ base: '2', md: '3' }} align="center">
          <Heading as={'h2'} size={{ base: 'sm', md: 'md' }}>
            Vrei să îți faci vocea auzită?
          </Heading>
          <Text color="textDark" maxW="2xl" textAlign="center" fontSize="xl">
            Crează-ți acum contul VOX Press!
          </Text>
        </Stack>
        <Stack
          spacing="3"
          direction={{ base: 'column', sm: 'row' }}
          justify="center"
          align={'center'}
        >
          <Link href="https://app.voxpress.ro/register">
            <Button bg={'brand.100'} _hover={'none'}>
              Începe acum
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Cta;
