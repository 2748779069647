import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { termsAndConditions } from '../components/legalData';

const TncPage = () => {
  return (
    <Stack
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
      pt={'110px'}
    >
      <Helmet>
        <title>Termeni & Conditii - VOX Press</title>
      </Helmet>
      <Flex
        flexDir={'column'}
        w={'full'}
        maxW={'100rem'}
        flexDirection={'column'}
      >
        <Heading as={'h1'}>Termeni & Conditii</Heading>
        <Text>Ultima Actualizare Martie 09, 2024</Text>

        <Accordion my="2rem" allowMultiple>
          {termsAndConditions.map((item) => (
            <AccordionItem key={item.title}>
              <AccordionButton>
                <Box flex={1} textAlign="left">
                  <Heading as="h2" fontSize="2xl" textTransform="uppercase">
                    {item.title}
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                {item.continut.map((item, index) => (
                  <>
                    {item.subtitlu !== '' && (
                      <Heading as={'h3'}>{item.subtitlu}</Heading>
                    )}
                    {item.text.map((item, index) => (
                      <Box key={index} mb="1rem" textAlign="justify">
                        {item}
                      </Box>
                    ))}
                  </>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Stack>
  );
};

export default TncPage;
