import {
  Divider,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link as LinkR } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';

const Footer = () => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      py={'2rem'}
      gap={'2rem'}
    >
      <HStack
        w={'full'}
        maxW={'100rem'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Stack alignItems={{ base: 'center', lg: 'flex-start' }}>
          <Image w="100px" src={'/Logo.png'} alt={'VOX Press'} />
          <Text>Partenerul tău în publicitate</Text>
        </Stack>
        <Stack>
          <Heading as={'h3'} fontSize={'1.25rem'}>
            Legal
          </Heading>
          <Divider />
          <LinkR to={'/termeni-conditii'}>Termeni și Condiții</LinkR>
          <LinkR to={'/gdpr'}>Politica de confidențialitate</LinkR>
        </Stack>
      </HStack>
      <HStack
        w={'full'}
        maxW={'100rem'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Text>
          VOX Press <strong>&#9400; {new Date().getFullYear()}</strong> Toate
          drepturile rezervate.{' '}
        </Text>

        <HStack>
          {' '}
          <Link
            fontSize={'2rem'}
            _hover={{ color: '#4267B2' }}
            href="https://www.facebook.com/profile.php?id=61557184912150"
            target="_blank"
            aria-label="facebook"
            rel="noreferrer"
          >
            <Icon as={FaFacebook} />
          </Link>
          <Link
            target="_blank"
            aria-label="anpc"
            rel="noreferrer"
            href="https://anpc.ro/ce-este-sal/"
          >
            <Image w={'100px'} src={'/images/anpc-sal.svg'} alt="ANPC - Sal" />
          </Link>
          <Link
            target="_blank"
            aria-label="anpc"
            rel="noreferrer"
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
          >
            <Image w={'100px'} src={'/images/anpc-sol.svg'} alt="ANPC - Sol" />
          </Link>
        </HStack>
      </HStack>
    </Stack>
  );
};

export default Footer;
