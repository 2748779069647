import { extendTheme } from '@chakra-ui/react';

export const myTheme = extendTheme({
  colors: {
    brand: {
      100: '#f8f8f8',
      101: '#d3d3d3',
      201: '#4f91c7',
      200: '#66b1ee',
      300: '#1d3344',
      301: '#082033',
    },

    title: '#e1e1e1',
    text: '#e4e4e4',
  },
  breakpoints: {
    sm: '40rem', //640px
    md: '48rem', //768px
    lg: '64rem', //1024px
    xl: '80rem', //1280px
    '2xl': '96rem', //1536px
  },
});
