import { Box, Heading, Stack, chakra } from '@chakra-ui/react';
import React from 'react';

const Hero = () => {
  return (
    <Stack
      w={'full'}
      h={{ base: '240px', lg: '100vh' }}
      position={'relative'}
      alignItems={'center'}
      justifyContent={'center'}
      overflow={'hidden'}
    >
      <Box
        w={'full'}
        position={'absolute'}
        zIndex={2}
        inset={0}
        bg={'rgba(0,0,0,0.7)'}
      ></Box>
      <chakra.video
        position={'absolute'}
        zIndex={1}
        inset={0}
        w={'full'}
        objectFit={'cover'}
        autoPlay
        controls={false}
        loop
        muted
        type={'video/mp4'}
        src={'/videos/video.mp4'}
      />
      <Stack
        zIndex={3}
        w={{ base: '330px', lg: '50rem' }}
        textAlign={'center'}
        gap={'1.5rem'}
      >
        <Heading as={'h1'} fontSize={{ base: '1.35rem', lg: '3.5rem' }}>
          Crește-ți vizibilitatea și impactul cu ajutorul nostru!
        </Heading>
        <Heading
          fontWeight={'normal'}
          as={'h2'}
          fontSize={{ base: '1rem', lg: '1.75rem' }}
          lineHeight={'1'}
        >
          Maximizează-ți expunerea prin publicarea în zeci de ziare. Începe acum
          pentru a-ți consolida prezența în mediul online.
        </Heading>
      </Stack>
    </Stack>
  );
};

export default Hero;
