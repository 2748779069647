import {
  Button,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const AreaComponent = ({ data }) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <HStack
      id={data.id}
      alignItems={'center'}
      justifyContent={'center'}
      gap={'2rem'}
      flexDir={{ base: data.flexColumn, lg: data.flexRow }}
      bg={data.bg}
      color={data.bg && 'brand.301'}
      py={'2rem'}
      w={'full'}
      minH={'50rem'}
    >
      <Stack
        w={{ base: '300px', lg: 'fit-content' }}
        data-aos={data.AosImg}
        data-aos-duration={data.AosDuration}
        data-aos-easing={data.AosEasing}
        data-aos-delay={data.AosDelayImg}
      >
        <Image
          w={{ base: '300px', lg: '500px' }}
          objectFit={'contain'}
          src={data.src}
          alt={data.nume}
        />
      </Stack>
      <Stack
        px={'4rem'}
        lineHeight={'2'}
        w={{ base: 'full', lg: '700px' }}
        data-aos={data.AosTxt}
        data-aos-duration={data.AosDuration}
        data-aos-easing={data.AosEasing}
        data-aos-delay={data.AosDelayTxt}
      >
        <Heading
          as={'h2'}
          fontSize={'1.5rem'}
          textAlign={{ base: 'center', lg: 'left' }}
        >
          {data.titlu}
        </Heading>
        <Text fontSize={'1.1rem'}>{data.descriere}</Text>
        {data.buton && (
          <Link href={data.buttonLink}>
            <Button
              bg={'brand.300'}
              _hover={{
                color: 'brand.300',
                bg: 'brand.100',
                boxShadow: '0 0 1rem rgba(29, 51, 68,0.8)',
              }}
              color={'brand.100'}
            >
              {data.buttonText}
            </Button>
          </Link>
        )}
      </Stack>
    </HStack>
  );
};

export default AreaComponent;
