import { ArrowUpIcon } from '@chakra-ui/icons';
import { Box, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const ScrollTopButton = () => {
  const [view, setView] = useState(false);
  const scrollEvent = (e) => {
    window.scrollY >= 80 ? setView(true) : setView(false);
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);

    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <Box>
      <IconButton
        position={'fixed'}
        bottom={'3rem'}
        right={'4rem'}
        zIndex={10000}
        display={view ? 'flex' : 'none'}
        onClick={scrollTop}
        bg={'brand.300'}
        color={'brand.100'}
        fontSize="2rem"
        isRound
        icon={<ArrowUpIcon />}
      />
    </Box>
  );
};

export default ScrollTopButton;
