export const despreInfo = {
  id: 'despre',
  flexColumn: 'column',
  flexRow: 'row',
  nume: 'Despre VOX Press',
  src: '/images/1.svg',
  titlu: 'Vrei să-ți faci vocea auzită peste tot?',
  descriere:
    'Cu VOXPress, este acum posibil! Simplu și rapid, îți creezi un cont și ai acces la postarea directă în zeci de ziare online.Intră acum în lumea jurnalismului digital cu VOXPress și bucură-te de experiența completă!',
  AosTxt: 'fade-left',
  AosImg: 'fade-right',
  AosDuration: '1000',
  AosEasing: 'ease-in-out',
  AosDelayImg: '400',
  AosDelayText: '100',
};

export const discoverInfo = {
  id: 'descopera',
  flexColumn: 'column',
  flexRow: 'row-reverse',
  nume: 'Descopera VOX Press',
  src: '/images/2.svg',
  titlu: 'Bine ai venit la platforma noastră de publicare online!',
  descriere:
    'Oferim o soluție completă pentru publicarea articolelor tale în timp real pe zeci de ziare online. Beneficiază de editare în timp real, SEO asigurat și distribuție largă pentru a-ți maximiza vizibilitatea și impactul. Indiferent de domeniul tău, te ajutăm să îți faci vocea auzită. Abonează-te acum și transformă-ți ideile în realitate!',
  AosImgt: 'fade-left',
  AosTxt: 'fade-right',
  AosDuration: '1000',
  AosEasing: 'ease-in-out',
  AosDelayImg: '400',
  AosDelayText: '100',
};

export const joinInfo = {
  id: 'cta',
  bg: 'brand.100',
  flexColumn: 'column',
  flexRow: 'row',
  nume: 'Despre VOX Press',
  src: '/images/3.svg',
  titlu: 'Alătură-te comunității noastre și începe să publici!',
  descriere:
    'Înscrie-te acum pe platforma noastră și începe să publici articole în timp real în zeci de ziare online. Nu mai pierde timpul! Transformă-ți ideile în realitate și asigură-te că mesajul tău ajunge la mii de oameni. Click mai jos pentru a te înscrie astăzi și a începe să construiești impactul pe care îl meriți!',
  AosTxt: 'fade-left',
  AosImg: 'fade-right',
  AosDuration: '1000',
  AosEasing: 'ease-in-out',
  AosDelayImg: '400',
  AosDelayText: '100',
  buton: true,
  buttonText: 'Începe acum',
  buttonLink: 'https://app.voxpress.ro/register',
};

export const serviciiInfo = [
  {
    nume: 'Publicare Instantanee',
    descriere:
      'Postează articolele tale în timp real pe zeci de ziare online. Nu mai aștepta ca mesajul tău să fie auzit – cu publicarea instantanee, asigurăm că fiecare idee importantă ajunge rapid la publicul tău țintă.',
    src: '/images/s1.svg',
    AosEffect: 'fade-right',
    AosDuration: '1000',
    AosEasing: 'ease-in-out',
    AosDelay: '200',
  },
  {
    nume: 'SEO Optimizat',
    descriere:
      'Aplicația noastră oferă o soluție SEO optimizată pentru a îmbunătăți vizibilitatea articolului dumneavoastră în motoarele de căutare. Cu tehnici avansate de optimizare, garantăm că paginile dvs. sunt indexate corect și apar în primele rezultate. ',
    src: '/images/s3.svg',
    AosEffect: 'fade-down',
    AosDuration: '1000',
    AosEasing: 'ease-in-out',
    AosDelay: '200',
  },
  {
    nume: 'Editare în Timp Real',
    descriere:
      'Modifică și optimizează articolele tale oricând dorești, direct de pe platforma noastră. Fii mereu relevant și precis, adaptându-ți conținutul în funcție de nevoile și feedback-ul cititorilor tăi, fără niciun efort suplimentar.',
    src: '/images/s2.svg',
    AosEffect: 'fade-left',
    AosDuration: '1000',
    AosEasing: 'ease-in-out',
    AosDelay: '200',
  },
];
